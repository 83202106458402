export enum EMPLOYEE_PERMISSION_POS {
    ALL = 'all',
    MOVE = 'move',
    DISCOUNT = 'discount',
    SET_PRICE = 'set_price',
    PAYMENT = 'payment',
    VOID_ITEMS = 'void_items',
    VOID_BILL = 'void_bill',
    
}

export enum EMPLOYEE_PERMISSION_PAGES {
    ALL = 'all',
    POS = 'pos',
    REPORTS = 'reports',
    SETTINGS = 'settings',
    MENU = 'menu',
    USERS = 'users',
    INVENTORY = 'inventory',
    DASHBOARD = 'dashboard',
    DISABLE_DASHBOARD_SALES = 'disable_sales_dashboard',
    HISTORY = 'logs',
}


export const EMPLOYEE_PERMISSIONS_LINK_TO_PATH = {
    [EMPLOYEE_PERMISSION_POS.ALL] : '/',
    [EMPLOYEE_PERMISSION_PAGES.POS] : '/pos',
    [EMPLOYEE_PERMISSION_PAGES.REPORTS] : '/reports',
    [EMPLOYEE_PERMISSION_PAGES.SETTINGS] : '/settings',
    [EMPLOYEE_PERMISSION_PAGES.MENU] : '/menu/items',
    [EMPLOYEE_PERMISSION_PAGES.USERS] : '/users',
    [EMPLOYEE_PERMISSION_PAGES.INVENTORY] : '/inventory',
    [EMPLOYEE_PERMISSION_PAGES.DASHBOARD] : '/dashboard',
    [EMPLOYEE_PERMISSION_PAGES.HISTORY] : '/logs',
}

export const EMPLOYEE_PATH_TO_PERMISSIONS = {
    '/': EMPLOYEE_PERMISSION_POS.ALL,
    '/users': EMPLOYEE_PERMISSION_PAGES.USERS,
    '/logs': EMPLOYEE_PERMISSION_PAGES.HISTORY,

    //POS
    '/pos': EMPLOYEE_PERMISSION_PAGES.POS,
    '/pos/cart': EMPLOYEE_PERMISSION_PAGES.POS,


    //REPORT
    '/reports': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/summary-daily': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/receipts': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/products': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/products-daily': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/transaction-by-hour': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/products-voided': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/activity-log': EMPLOYEE_PERMISSION_PAGES.REPORTS,
    '/reports/sales-by-employee': EMPLOYEE_PERMISSION_PAGES.REPORTS,


    //MENU
    '/menu': EMPLOYEE_PERMISSION_PAGES.MENU,
    '/menu/items': EMPLOYEE_PERMISSION_PAGES.MENU,
    '/menu/groups': EMPLOYEE_PERMISSION_PAGES.MENU,
    '/menu/addon': EMPLOYEE_PERMISSION_PAGES.MENU,
    '/menu/qrcode': EMPLOYEE_PERMISSION_PAGES.MENU,

    //INVENTORY
    '/inventory/list': EMPLOYEE_PERMISSION_PAGES.INVENTORY,
    '/inventory/report': EMPLOYEE_PERMISSION_PAGES.INVENTORY,
    '/inventory/add': EMPLOYEE_PERMISSION_PAGES.INVENTORY,

    //DASBOARD
    '/dashboard/live': EMPLOYEE_PERMISSION_PAGES.DASHBOARD,
    '/dashboard/receipts': EMPLOYEE_PERMISSION_PAGES.DASHBOARD,
    '/dashboard/products': EMPLOYEE_PERMISSION_PAGES.DASHBOARD,
    '/dashboard/voided-items': EMPLOYEE_PERMISSION_PAGES.DASHBOARD,
    '/dashboard/close': EMPLOYEE_PERMISSION_PAGES.DASHBOARD,


    //SETTING
    '/settings': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/employees': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/tables': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/payments': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/pairing': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/pair-reports-mobile': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/mobile-connect': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
    '/settings/printers': EMPLOYEE_PERMISSION_PAGES.SETTINGS,
        


}

export interface Employee { 
    storeId : string
    employeeId : string
    name : string
    pin : string
    repin : string
    pages_permissions : EMPLOYEE_PERMISSION_PAGES
    pos_actions : EMPLOYEE_PERMISSION_POS
    createdAt : string
}